import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import Acknowledged from '../images/acknowledged.png';
import Tend from '../images/tend.png';
import Github from '../images/github.png';
import SWEShape from './shapes/SWEShape';

const SWE_DESCRIPTION = 'as a professional software engineer, most of my development work is privatized. however, here are some of my public projects.';

const ImagePortfolioBox = styled.a.attrs({
  className: 'w-28 h-28 xl:w-44 xl:h-44 m-2 border-solid border border-gray-300',
})`
  background: url("${(props) => props.imageUrl}") no-repeat center center;
  background-size: contain;
  position: relative;
  &:before {
    content: "";
    border: dashed 1px gray;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0.5rem;
    left: 0.5rem;
    z-index: -1;
  }
`;

const ImagePortfolioHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 text-center text-white opacity-0 hover:opacity-100 transition-opacity',
})``;

const SWEPortfolio = () => (
  <div className="flex flex-row justify-center xl:justify-start  w-full z-10 mb-12 xl:mb-0">
    <ImagePortfolioBox imageUrl={Acknowledged} href="https://github.com/lowewenzel/acknowledged-bot" target="_blank" rel="noopener noreferrer">
      <ImagePortfolioHoverTitle>
        AcknowledgedBot
      </ImagePortfolioHoverTitle>
    </ImagePortfolioBox>
    <ImagePortfolioBox imageUrl={Tend} href="https://tend.wnzl.dev" target="_blank" rel="noopener noreferrer">
      <ImagePortfolioHoverTitle>
        Tend
      </ImagePortfolioHoverTitle>
    </ImagePortfolioBox>
    <ImagePortfolioBox imageUrl={Github} href="https://github.com/lowewenzel" target="_blank" rel="noopener noreferrer">
      <ImagePortfolioHoverTitle>
        Github
      </ImagePortfolioHoverTitle>
    </ImagePortfolioBox>
  </div>
);

const StripedSWEPolygon = styled(SWEShape)`
  position: absolute;
  left: 40px;
  top: 40px;
`;

const SWEPolygons = () => (
  <div className="z-0 absolute w-2/6 h-full right-0 left-2/4 mt-80 xl:mt-0 opacity-20 xl:opacity-100">
    <SWEShape />
    <StripedSWEPolygon noFill />
    {/* <SWEPolygon src={SWEShape} />
    <StripedSWEPolygon src={SWEShape} /> */}

  </div>
);

const Software = () => (
  <Parallax>
    <div className="w-full h-6/12 xl:h-screen flex flex-col justify-center items-center" id="software">
      <div className="flex flex-col-reverse justify-end xl:grid xl:grid-cols-2 w-full  h-full flex-1 -mt-64 xl:mt-0">
        <div className="container flex items-center justify-center z-10">
          <div className="container w-9/12">

            <motion.h1
              className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold"
              whileHover={{ y: -10 }}
              transition={{
                duration: 0.5,
              }}
            >
              software engineer
            </motion.h1>
            <div className="container my-7 max-w-xl">
              <p className="text-2xl uppercase">
                {SWE_DESCRIPTION}
              </p>

            </div>
          </div>
        </div>
        <div className="container flex items-center justify-center">
          <SWEPolygons />
          <SWEPortfolio />
        </div>
      </div>

    </div>
  </Parallax>
);

export default Software;
