import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import WenzelFilled from '../images/logo-filled.svg';
import WenzelOutline from '../images/logo-empty.svg';

const WENZEL_MARGIN = 180;

const SUMMARY_TEXT = 'i am a software engineer at zendesk with previous work in motion graphics, ux, and marketing, spending my extra time in hobby filmmaking.';

const WenzelTitle = styled(motion.h1).attrs({ className: 'text-5xl lg:text-6xl xl:text-7.5xl whitespace-nowrap font-display font-bold text-right md:text-left' })`

`;

const Polygon = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skewY(-20deg);
  background: transparent linear-gradient(152deg, #DDE2E1 0%, var(--unnamed-color-395c6b) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(152deg, #DDE2E1 0%, #395C6B 100%) 0% 0% no-repeat padding-box;
`;

const StripedPolygon = styled(Polygon)`
  position: absolute;
  left: 40px;
  top: 0px;
  background: initial;
  transform: skewY(-20deg) translateY(40px);
  border: 2px dashed #707070;
`;

const Polygons = () => (
  <div className="z-0 absolute w-2/6 h-full left-0">
    <StripedPolygon />

    <Polygon />
  </div>
);

const WenzelsContainer = styled.div`
  position: relative;
  &:before {
    content: 'art by jaerica';
    text-transform: uppercase;
    font-size: 0.5rem;
    position: absolute;
    bottom: -20px;
    margin-right: 144px;
    color: #fff;
  }
`;

const LeftWenzel = styled.img`
  margin-right: -${WENZEL_MARGIN}px;
  filter: invert(1)
`;

const CenterWenzel = styled.img`
  z-index: 10;
`;

const RightWenzel = styled.img`
  margin-left: -${WENZEL_MARGIN}px;
`;

const Wenzels = () => (
  <div className="absolute grid w-screen grid-cols-3 left-0">
    <WenzelsContainer className=" h-80 col-span-2 z-10 flex flex-row justify-center">
      <LeftWenzel src={WenzelOutline} />
      <CenterWenzel src={WenzelFilled} />
      <RightWenzel src={WenzelOutline} />
    </WenzelsContainer>
  </div>
);

const Summary = () => (
  <div className="p-12 pt-0 xl:p-0 w-full h-screen flex flex-col justify-center items-center">
    <div className="flex flex-col-reverse justify-end xl:grid xl:grid-rows-1 xl:grid-cols-2 w-full h-full flex-1">
      <div className="container opacity-20 xl:opacity-100 flex items-center justify-center">
        <Polygons />
        <Wenzels />
      </div>
      <div className="container flex items-center justify-center z-10">
        <div className="container">

          <WenzelTitle
            className=""
            whileHover={{ y: -10 }}
            transition={{
              duration: 0.5,
            }}
          >
            summary
          </WenzelTitle>
          <div className="container my-7 max-w-xl text-right md:text-left">
            <p className="text-2xl uppercase">
              {SUMMARY_TEXT}
            </p>

          </div>
        </div>
      </div>
    </div>

  </div>
);

export default Summary;
