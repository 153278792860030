/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

const SvgSweshape = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1000}
    height={780}
    {...props}
  >
    {
      props.noFill
        ? <path d="M198.121 224.756L960 0v736.142H0z" stroke="#333" strokeWidth="1" fill="none" />
        : <path d="M198.121 224.756L960 0v736.142H0z" fill="#dedede" />
    }
  </svg>
);

SvgSweshape.propTypes = {
  noFill: PropTypes.bool,
};

SvgSweshape.defaultProps = {
  noFill: false,
};

export default SvgSweshape;
