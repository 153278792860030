import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { PlayCircle } from 'react-feather';

import inconcert from '../images/inconcert.png';
import hlne from '../images/hlne.png';
import newgrad from '../images/newgrad.png';
import lowegarden from '../images/lowegarden.png';

const UpperPoly = styled.div.attrs({
  className: 'bg-black h-64 -mt-20 w-full',
})`
  transform: skewY(-5deg);
`;

const VideoWrapper = styled.div.attrs({
  className: 'mb-44',
})`
  position: relative;
  padding-bottom: ${(props) => (props.sixteen ? 56.25 : 36.23)}%; /* 2.76:1 */
  height: 0;
  overflow: hidden;
  width: 100%;
`;

const IframeVideo = styled.iframe`
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoIcon = styled(motion.div).attrs({
  className: 'w-72 h-32',
})`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  cursor: pointer;
`;

const VideoHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 justify-center text-white opacity-0 hover:opacity-100 transition-opacity',
})``;

const VideoRow = ({
  image, name, desc, reverse, videoSrc, handleClickVideoSrc,
}) => {
  const handleClickIcon = useCallback(() => {
    handleClickVideoSrc(videoSrc);
  }, []);

  return (
    <div className={`flex flex-col xl:${reverse ? 'flex-row-reverse' : 'flex-row'} justify-center items-center mb-24`}>
      <VideoIcon
        src={image}
        whileHover={{ y: -10 }}
        transition={{
          duration: 0.5,
        }}
        onClick={handleClickIcon}
      >
        <VideoHoverTitle><PlayCircle className="self-center" /></VideoHoverTitle>

      </VideoIcon>
      <hr className="hidden xl:block w-64 border-dashed" />
      <div className="mx-8">
        <h6
          className={`text-2xl text-center xl:${reverse ? 'text-right' : 'text-left'} font-bold text-white uppercase mt-4 xl:mt-0`}
        >
          {name}
        </h6>
        <p
          className={`text-md xl:text-xl text-center xl:${reverse ? 'text-right' : 'text-left'} uppercase text-gray-400`}
        >
          <br className="hidden xl:block" />
          {desc}
        </p>
      </div>
    </div>
  );
};

VideoRow.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  handleClickVideoSrc: PropTypes.func.isRequired,
  reverse: PropTypes.bool,
};

VideoRow.defaultProps = {
  reverse: false,
};

const Videography = () => {
  const [videoSrc, setVideoSrc] = useState('https://www.youtube.com/embed/BVXAQADq5go?rel=0&modestbranding=1&autohide=1&showinfo=0');
  const videoRef = useRef(null);

  const setSrcAndScroll = (src) => {
    setVideoSrc(src);
    videoRef.current.scrollIntoView();
  };

  return (
    <div className="bg-black w-full min-h-screen flex flex-col items-center mt-44 pb-96" id="videography">
      <UpperPoly />
      <div className="flex flex-row w-full ">
        <div className="container" />
        <div className="container flex z-10 mb-44">
          <div className="container p-12 xl:p-0">
            <motion.h1
              className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold text-white"
              whileHover={{ y: -10 }}
              transition={{
                duration: 0.5,
              }}
            >
              videographer
            </motion.h1>
            <div className="container my-7 max-w-xl text-right md:text-left text-white">
              <p className="text-2xl uppercase">
                storytelling via motion pictures
              </p>
            </div>
          </div>
        </div>
      </div>
      <VideoWrapper
        name="videoPlayer"
        id="videoPlayer"
        ref={videoRef}
        sixteen={videoSrc !== 'https://www.youtube.com/embed/BVXAQADq5go?rel=0&modestbranding=1&autohide=1&showinfo=0'}
      >

        <IframeVideo height="696" width="1920" src={videoSrc} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </VideoWrapper>
      <div className="flex flex-col items-center w-full">
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/wcMiAyUiO4I?rel=0&modestbranding=1&autohide=1&showinfo=0" image={inconcert} name="Harmony in Unity (2021)" desc="sony fs5, sirui 24mm, 35mm, 50mm anamorphic" />
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/S-21IPuACnE?rel=0&modestbranding=1&autohide=1&showinfo=0" reverse image={hlne} name="His Love Never Ends (2020)" desc="sony a7iii, samyang 35mm, 50mm" />
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/UwcrPIqQJOE?rel=0&modestbranding=1&autohide=1&showinfo=0" image={newgrad} name="New Grad (2020)" desc="sony fs5, sirui 50mm anamorphic" />
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/BVXAQADq5go?rel=0&modestbranding=1&autohide=1&showinfo=0" reverse image={lowegarden} name="Lowe Garden (2020)" desc="sony fs5, samyang 85mm, 2x isco ultrastar anamorphic" />
      </div>
    </div>
  );
};

export default Videography;
