import React from 'react';
import styled from 'styled-components';

const TopHeader = styled.div.attrs({
  className: 'fixed top-0 left-0 right-0 px-12 xl:px-24 pt-12 z-50',
})``;

const TopHeaderLinks = styled.div.attrs({
  className: 'flex justify-between align-middle mb-6',
})``;

const Header = () => (
  <TopHeader className="font-secondary">
    <TopHeaderLinks>
      <h6 className="text-xl">wnzls</h6>
      <a href="mailto:hi@wnzl.dev"><h6 className="text-xl">contact</h6></a>
    </TopHeaderLinks>
    <hr className="border-gray-200" />
  </TopHeader>
);

export default Header;
