import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import {
  Instagram, GitHub, Linkedin, FileText,
} from 'react-feather';

const IconContainer = styled.div.attrs({
  className: 'h-4 xl:h-6 mb-4 color-primary',
})`
  cursor: pointer;
`;

const BorderedDiv = styled.div`
  ${'' /* width: 24px; */}
`;

const SocialLinks = () => (
  <div className="fixed left-12 xl:left-24 bottom-4 flex flex-col-reverse z-50">
    <BorderedDiv className="h-12 xl:h-24 w-px border-r border-primary self-center" />
    <IconContainer
      as={motion.a}
      href="https://instagram.com/wnzls"
      target="_blank"
      whileHover={{ scale: 1.2, rotate: -10 }}
    >
      <Instagram className="stroke-1 w-4 xl:w-6" />
    </IconContainer>
    <IconContainer
      as={motion.a}
      href="https://github.com/lowewenzel"
      target="_blank"
      whileHover={{ scale: 1.2, rotate: -10 }}
    >
      <GitHub className="stroke-1 w-4 xl:w-6" />
    </IconContainer>
    <IconContainer
      as={motion.a}
      href="https://linkedin.com/in/lowewenzel"
      target="_blank"
      whileHover={{ scale: 1.2, rotate: -10 }}
    >
      <Linkedin className="stroke-1 w-4 xl:w-6" />
    </IconContainer>
    <IconContainer
      as={motion.a}
      href="mailto:hi@wnzl.dev"
      target="_blank"
      whileHover={{ scale: 1.2, rotate: -10 }}
    >
      <FileText className="stroke-1 w-4 xl:w-6" />
    </IconContainer>
  </div>
);

export default SocialLinks;
