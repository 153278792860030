import React, { useRef } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Layout from '../components/Layout';
import Header from '../components/Header';
import HeroTitle from '../components/HeroTitle';
import Summary from '../components/Summary';
import Software from '../components/Software';
import SocialLinks from '../components/SocialLinks';
import Videography from '../components/Videography';
import Footer from '../components/Footer';

const IndexPage = () => {
  const softwareRef = useRef(null);
  const videographyRef = useRef(null);

  const scrollToRef = (ref) => {
    // ugh
    if (ref === 'software') {
      document.getElementById('software').scrollIntoView();
    } else if (ref === 'videography') {
      document.getElementById('videography').scrollIntoView();
    }
  };

  return (
    <ParallaxProvider>
      <main>

        <Layout>
          <div className="z-10">
            <SocialLinks />
            <Header />

            <HeroTitle scrollToSoftware={() => scrollToRef('software')} scrollToVideography={() => scrollToRef('videography')} />

            <Summary />

            <Software ref={softwareRef} />

            <Videography ref={videographyRef} />
            <Footer />
          </div>
        </Layout>
      </main>
    </ParallaxProvider>
  );
};

export default IndexPage;
